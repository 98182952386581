<template>
  <div class="payment">
    <div class="payment-content">
      <div class="payment-content-header">
        <h2>Pagamento</h2>
        <div class="secure-payment">🔒 Pagamento Seguro</div>
      </div>
      <div class="payment-content-timer">
        <p>⏰ Tempo restante: 14 minutos 55 segundos</p>
      </div>
      <div class="payment-content-order">
        <h3>Resumo do seu pedido.</h3>
        <div class="total">
          <span>Total</span>
          <span>€ 20,00</span>
        </div>
      </div>
      <div class="payment-content-description">
        <span>Carregamento</span>
        <span>€ 20,00</span>
      </div>

      <div class="payment-content-express">
        <div class="payment-content-express-box">
          <h3>Pagamento expresso</h3>
          <button class="btn-google-pay">Compre com o GPay</button>
          <button class="btn-apple-pay">Pagar com Apple Pay</button>
        </div>
      </div>
      <div class="payment-content-method">
        <h3 class="title">Payment method</h3>
        <button class="credit-card">
          <span>Cartão Credito </span>
        </button>
        <div class="payment-info">
          O seu pagamento beneficia de normas de segurança. Dependendo do tipo
          de seu cartão, será redirecionado para o seu banco para se autenticar.
        </div>
      </div>
    </div>
    <div class="payment-content-btn">
      <button @click="nextStep" class="btn-submit">Pagar</button>
      <button class="btn-cancel">Cancelar pagamento</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    nextStep() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
