<template>
  <div>
    <form @submit.prevent="nextStep">
      <div class="form-group">
        <label for="phone">Telemóvel</label>
        <input
          type="tel"
          id="localPhone"
          v-model="localPhone"
          required
          placeholder="Introduza o número de telemóvel"
        />
      </div>

      <div class="form-group">
        <label for="bracelet">Código da Pulseira</label>
        <input
          type="text"
          id="localBracelet"
          v-model="localBracelet"
          required
          placeholder="Introduza o código da pulseira"
        />
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          id="localEmail"
          v-model="localEmail"
          required
          placeholder="Introduza o email"
        />
      </div>

      <button type="submit" class="btn-submit">Próximo</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ["phone", "bracelet", "email"],
  data() {
    return {
      localPhone: this.phone,
      localBracelet: this.bracelet,
      localEmail: this.email,
    };
  },
  methods: {
    nextStep() {
      this.$emit("updateData", {
        phone: this.localPhone,
        bracelet: this.localBracelet,
        email: this.localEmail,
      });
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
