<template>
  <div class="confirmation">
    <div class="confirmation-header">
      <h2>MEO KALORAMA 2024 - Carregamento rapido</h2>
      <div class="language-selector">
        🌐
        <select @change="changeLanguage($event)" v-model="selectedLanguage">
          <option value="PT">PT</option>
          <option value="FR">FR</option>
          <option value="EN">EN</option>
        </select>
      </div>
    </div>
    <p>Ao clicar proximo, aceito os Termos e Condições</p>
    <div class="confirmation-button">
      <button class="btn-submit" @click="nextStep">Confirmar</button>
      <button class="btn-back" @click="prevStep">Voltar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["phone", "bracelet", "email"],
  data() {
    return {
      selectedLanguage: "PT",
    };
  },
  methods: {
    nextStep() {
      this.$emit("next");
    },
    prevStep() {
      this.$emit("prev");
    },
    changeLanguage(event) {
      const selectedLang = event.target.value;
      this.selectedLanguage = selectedLang;
      this.$emit("languageChange", selectedLang);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
