<template>
  <div class="progress-container">
    <div class="progress-container">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    </div>

    <div class="form-wrapper">
      <div class="form-container">
        <component
          :is="currentComponent"
          :phone="phone"
          :bracelet="bracelet"
          :email="email"
          @updateData="updateData"
          @next="nextStep"
          @prev="prevStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Step1 from "./SiteView.vue";
import Step2 from "./EventView.vue";
import Step3 from "./TopUpView.vue";
import Step4 from "./PaymentView.vue";

export default {
  data() {
    return {
      currentStep: 1,
      phone: "",
      bracelet: "",
      email: "",
      progress: 25,
    };
  },
  computed: {
    currentComponent() {
      if (this.currentStep === 1) return Step1;
      if (this.currentStep === 2) return Step2;
      if (this.currentStep === 3) return Step3;
      return Step4;
    },
  },
  methods: {
    updateData(data) {
      this.phone = data.phone;
      this.bracelet = data.bracelet;
      this.email = data.email;
    },
    nextStep() {
      if (this.currentStep < 4) {
        this.currentStep++;
        this.updateProgress();
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.updateProgress();
      }
    },
    updateProgress() {
      this.progress =
        this.currentStep === 1
          ? 25
          : this.currentStep === 2
          ? 50
          : this.currentStep === 3
          ? 75
          : 100;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
